import React, { useState, useEffect } from 'react';
import ProductCard from './ProductCard'; // Adjust the import path as necessary

interface Product {
    id: number;
    sku: string;
    name: string;
    description: string;
    images: string | null;
    has_variations: boolean;
    variations: any;
    has_varying_price: boolean;
    price: {
        default: number;
    };
    sub_category_id: number;
    published: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    stock: {
        quantity: number;
    };
}

const ProductsList: React.FC = () => {
    const [products, setProducts] = useState<Product[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetch('https://simpleapp.ogechik.site/api/products?page=1&pageSize=5')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch products');
                }
                return response.json();
            })
            .then((data) => {
                setProducts(data.data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading products...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <section>
            <div className="text-center py-4 mt-8">
                <h1 className="text-3xl font-bold mb-8">new arrivals</h1>
                <div className="grid grid-cols-2 gap-4 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-5">
                    {products.map((product) => (
                        <ProductCard key={product.id} product={product}/>
                    ))}
                </div>
            </div>
        </section>

    );
};

export default ProductsList;
