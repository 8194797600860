import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer className="bg-white text-black p-20">
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {/* Logo and description */}
                <div className="col-span-1">
                    {/* Latest Blog */}
                    <div>
                        <h5 className="font-semibold mb-4">Latest Blog</h5>
                        <div className="space-y-3">
                            <a href="#" className="flex items-center space-x-3 hover:text-gray-300">
                                <img src="https://via.placeholder.com/50" alt="Blog post" className="w-12 h-12 object-cover rounded"/>
                                <span>I think really important to design...</span>
                            </a>
                            <a href="#" className="flex items-center space-x-3 hover:text-gray-300">
                                <img src="https://via.placeholder.com/50" alt="Blog post" className="w-12 h-12 object-cover rounded"/>
                                <span>Recognizing the need is the primary...</span>
                            </a>
                        </div>
                    </div>
                    {/* Social icons */}
                    <div className="mt-4 flex space-x-3">
                        <a href="#" className="hover:text-gray-300">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="#" className="hover:text-gray-300">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="#" className="hover:text-gray-300">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a href="#" className="hover:text-gray-300">
                            <i className="fab fa-linkedin-in"></i>
                        </a>
                    </div>
                </div>

                {/* Links section */}
                <div>
                    <h5 className="font-semibold mb-4">Company</h5>
                    <ul className="space-y-2">
                        <li><a href="#" className="hover:text-gray-300">About company</a></li>
                        <li><a href="#" className="hover:text-gray-300">Company services</a></li>
                        <li><a href="#" className="hover:text-gray-300">Job opportunities</a></li>
                        <li><a href="#" className="hover:text-gray-300">Creative people</a></li>
                    </ul>
                </div>

                <div>
                    <h5 className="font-semibold mb-4">Customer</h5>
                    <ul className="space-y-2">
                        <li><a href="#" className="hover:text-gray-300">Client support</a></li>
                        <li><a href="#" className="hover:text-gray-300">Latest news</a></li>
                        <li><a href="#" className="hover:text-gray-300">Company story</a></li>
                        <li><a href="#" className="hover:text-gray-300">Pricing packages</a></li>
                    </ul>
                </div>
            </div>
            {/* Footer bottom */}
            <div className="border-t border-gray-100 mt-10 pt-5">
                <div className="container mx-auto flex justify-between text-sm">
                    <div className="space-x-4">
                        <a href="#" className="hover:text-gray-300">Privacy policy</a>
                        <a href="#" className="hover:text-gray-300">Legal notice</a>
                        <a href="#" className="hover:text-gray-300">Terms of service</a>
                    </div>
                    <p>© 2024 Offspring Co</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
