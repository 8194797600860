import React, { useState } from 'react';

export const menuItems = [
    {
        id: 1,
        title: "Clothing & Footwear",
        subItems: [
            "New Arrivals",
            "Clothing Sets",
            "Tops & T-Shirts",
            "Jeans & Pants",
            "Shoes",
            "Socks & Tights"
        ],
    },
    {
        id: 2,
        title: "Diapering",
        subItems: [
            "Diapers",
            "Wipes",
            "Diaper Bags",
            "Changing Pads",
            "Potty Training",
            "Diaper Rash Creams"
        ],
    },
    {
        id: 3,
        title: "Feeding",
        subItems: [
            "Bottles",
            "Breast Pumps",
            "High Chairs",
            "Pacifiers",
            "Bib & Burp Cloths",
            "Nursing Covers"
        ],
    },
    {
        id: 4,
        title: "Health & Safety",
        subItems: [
            "Thermometers",
            "Grooming Kits",
            "Nasal Aspirators",
            "Baby Monitors",
            "Safety Gates",
            "Health Kits"
        ],
    },
    {
        id: 5,
        title: "Bath & Skin Care",
        subItems: [
            "Bath Tubs",
            "Baby Soap",
            "Baby Shampoo",
            "Lotions",
            "Towels",
            "Sponges"
        ],
    },
    {
        id: 6,
        title: "Baby Gear",
        subItems: [
            "Strollers",
            "Car Seats",
            "Carriers",
            "Play Yards",
            "Swings",
            "Bouncers"
        ],
    },
    {
        id: 7,
        title: "Nursery",
        subItems: [
            "Cribs",
            "Bedding",
            "Furniture",
            "Decor",
            "Storage",
            "Mattresses"
        ],
    },
    {
        id: 8,
        title: "Books & Toys",
        subItems: [
            "Books",
            "Learning Toys",
            "Stuffed Animals",
            "Puzzles",
            "Activity Centers",
            "Building Blocks"
        ],
    },
    {
        id: 9,
        title: "For Mom",
        subItems: [
            "Maternity Wear",
            "Nursing Bras",
            "Belly Bands",
            "Skincare",
            "Maternity Pillows",
            "Nursing Pads"
        ],
    },
    {
        id: 10,
        title: "Brands",
        subItems: [
            "Brand A",
            "Brand B",
            "Brand C",
            "Brand D",
            "Brand E",
            "Brand F"
        ],
    }
];

const MegaMenu: React.FC = () => {
    const [openMenu, setOpenMenu] = useState<number | null>(null);

    const handleMouseEnter = (id: number) => {
        setOpenMenu(id);
    };

    const handleMouseLeave = () => {
        setOpenMenu(null);
    };

    return (
        <nav className="bg-white border-b">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center h-16">
                    <div className="flex justify-center space-x-8 w-full">
                        {menuItems.map((item) => (
                            <div
                                key={item.id}
                                className="relative"
                                onMouseEnter={() => handleMouseEnter(item.id)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <button className="text-gray-800 font-medium hover:text-gray-900 focus:outline-none">
                                    {item.title}
                                </button>
                                {openMenu === item.id && (
                                    <div className="absolute left-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                        <div className="py-1">
                                            {item.subItems.map((subItem, index) => (
                                                <a
                                                    key={index}
                                                    href="#"
                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                >
                                                    {subItem}
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default MegaMenu;
