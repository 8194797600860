import React from 'react';
import {User, ShoppingCart, Heart, Search, PhoneCall, Truck} from 'lucide-react';

const Header: React.FC = () => {
    return (
        <header className="flex items-center justify-between bg-white">
            <div className="flex items-center">
                <img
                    src="https://offspring.co.ke/wp-content/uploads/2021/05/NEW-01-1200x477.jpg"
                    alt="Logo"
                    className="h-20 mr-4"
                />
            </div>

            <div className="flex items-center w-full max-w-xl">
                <div className="flex items-center w-full border rounded-lg overflow-hidden">
                    <div className="flex items-center px-4 py-2 border-r">
                        <select className="bg-transparent outline-none cursor-pointer">
                            <option>All categories</option>
                            <option>Electronics</option>
                            <option>Fashion</option>
                            <option>Home & Garden</option>
                        </select>
                    </div>

                    {/* Search Input */}
                    <input
                        type="text"
                        placeholder="What are you looking for?"
                        className="w-full px-4 py-2 border-none focus:outline-none"
                    />

                    {/* Search Button */}
                    <button className="flex items-center justify-center bg-offspring p-3 text-white">
                        <Search className="h-5 w-5" />
                    </button>
                </div>
            </div>

            {/* Icons Section */}
            <div className="flex items-center space-x-4">
                <div className="flex items-center mr-8">
                    <Truck className="h-10 w-10 text-company mr-2"/>
                    <div>
                        <p className="text-xs font-medium text-gray-600">Country wide</p>
                        <p className="text-md font-semibold text-company">Delivery</p>
                    </div>
                </div>
                <div className="flex items-center mr-14">
                    <PhoneCall className="h-10 w-10 text-company mr-2"/>
                    <div>
                        <p className="text-xs font-medium text-gray-600">CALL ANYTIME</p>
                        <p className="text-lg font-semibold text-company">280 900 3434</p>
                    </div>
                </div>
                {/* Wishlist Icon */}
                <Heart className="h-6 w-6 text-gray-700 cursor-pointer"/>

                {/* Cart Icon */}
                <ShoppingCart className="h-6 w-6 text-gray-700 cursor-pointer"/>

                {/* Profile Icon */}
                <User className="h-6 w-6 text-gray-700 cursor-pointer"/>
            </div>
        </header>
    );
};

export default Header;
