import React from 'react';
export const serviceItems = [
    {
        id: 1,
        title: "Reliable Delivery",
        description: "Products delivered at your doorstep",
    },
    {
        id: 2,
        title: "Genuine Products",
        description: "True quality, true value",
    },
    {
        id: 3,
        title: "Secure Payments",
        description: "Protecting your transactions every step of the way",
    },
];

const ServiceSection: React.FC = () => {
    return (
        <div className="bg-gray-50 py-2">
            <div className="">
                <div className="flex justify-between items-center">
                    {serviceItems.map((item) => (
                        <div key={item.id} className="text-center flex-1    ">
                            <h3 className="text-sm font-semibold ">{item.title}</h3>
                            <p className="text-gray-600">{item.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServiceSection;
