import React from 'react';
import ProductsList from '../Components/ProductsList';
import Cta from "../Components/Cta";
import Sba from "../Components/Sba";
import ShopByBrandCarousel from "../Components/Sbb";
import BabyRegistry from "../Components/Babyregistry";
import Categories from "../Components/Categories";
import ServiceSection from "../Components/Service";
import HeaderSlider from "../Components/Slider";

const LandingPage: React.FC = () => {
    return (
        <div>
            <main className="">
                <ServiceSection />
                <HeaderSlider />
                <Categories />
                <BabyRegistry />
                <ProductsList/>
                <Cta/>
                <Sba/>
                <ShopByBrandCarousel />
            </main>
        </div>
    );
};

export default LandingPage;
