import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const brands = [
    { name: "carter's", logo: 'https://offspring.co.ke/wp-content/uploads/2021/04/carters_compact.png', alt: 'Carter\'s logo' },
    { name: "CarterLiebe", logo: 'https://offspring.co.ke/wp-content/uploads/2021/04/carterliebe_compact.png', alt: 'CarterLiebe logo' },
    { name: "Fisher-Price", logo: 'https://offspring.co.ke/wp-content/uploads/2021/04/fisher_compact.png', alt: 'Fisher-Price logo' },
    { name: "Gerber", logo: 'https://offspring.co.ke/wp-content/uploads/2021/04/gerber_compact.png', alt: 'Gerber logo' },
    { name: "Lamaze", logo: 'https://offspring.co.ke/wp-content/uploads/2021/04/lamaze_compact.png', alt: 'Lamaze logo' },
    { name: "Pampers", logo: 'https://offspring.co.ke/wp-content/uploads/2021/04/pampers_compact.png', alt: 'Pampers logo' },
];

const ShopByBrandCarousel: React.FC = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };

    return (
        <div className="text-center py-4 mt-8">
            <h1 className="text-3xl font-bold mb-8">shop by brand</h1>
            <Slider {...settings}>
        {brands.map((brand) => (
                    <div key={brand.name} className="p-4">
                        <div className="border border-gray-200 rounded-lg p-4 flex items-center justify-center transition hover:shadow-lg">
                            <img src={brand.logo} alt={brand.alt} className="h-10" />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ShopByBrandCarousel;
