import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// sliderData.ts
export const slides = [
    {
        id: 1,
        imgSrc: "https://www.naturebabyoutfitter.com/cdn/shop/files/24-446_MonterreyCollection_Email-Header_US_600x300.png?v=1724772098",
        title: "Slide Title 1",
        description: "This is the description for slide 1",
    },
    {
        id: 2,
        imgSrc: "https://www.naturebabyoutfitter.com/cdn/shop/files/24-446_MonterreyCollection_Email-Header_US_600x300.png?v=1724772098",
        title: "Slide Title 2",
        description: "This is the description for slide 2",
    },
];

const HeaderSlider: React.FC = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className="relative bg-white overflow-hidden" style={{ height: '70vh', zIndex: -1 }}>
            <Slider {...settings}>
                {slides.map((slide) => (
                    <div key={slide.id} className="relative h-full">
                        <img src={slide.imgSrc} alt={slide.title} className="w-full h-full object-cover" />
                        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
                            <h2 className="text-3xl font-semibold text-white">{slide.title}</h2>
                            <p className="mt-2 text-white">{slide.description}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default HeaderSlider;
