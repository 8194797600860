import React from 'react';

const BabyRegistry: React.FC = () => {
    return (
        <section className="">
            <div>
                <div
                    className="xl:p-16 gap-32 p-12 rounded-sm bg-gradient-to-r from-teal-600 to-violet-600 flex  justify-between flex-col-reverse lg:flex-row">
                    <div className="w-full lg:w-2/6 relative">
                        <img src="https://www.pngkit.com/png/full/6-62341_the-transforming-birth-fund-african-american-baby.png" alt="CTA tailwind section"
                             className="xl:absolute xl:bottom-0 rounded-t-3xl -mb-12 mx-auto lg:-mb-12 xl:-mb-24 lg:mx-0 object-cover"/>
                    </div>
                    <div className="w-full lg:w-2/3">
                        <h2 className="font-manrope text-3xl text-white font-semibold mb-7 text-center lg:text-left">Baby Registry</h2>
                        <p className="text-lg text-white leading-8 mb-12 text-center lg:text-left">Congratulations on
                            your upcoming arrival!
                            <br/><br/>
                            Prepare your little one with ease by shopping essential baby items from top brands, manage
                            your registry online and enjoy exclusive perks.
                            <br/><br/>
                            Start creating your perfect registry today!</p>
                        <button className="bg-white font-bold p-4">Create Registry</button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BabyRegistry;
