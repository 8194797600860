import React from 'react';
import { ShoppingCart, Star } from 'lucide-react';

interface Product {
    id: number;
    sku: string;
    name: string;
    description: string;
    images: string | null;
    has_variations: boolean;
    variations: any;
    has_varying_price: boolean;
    price: {
        default: number;
    };
    sub_category_id: number;
    published: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    stock: {
        quantity: number;
    };
}

interface ProductCardProps {
    product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
    return (
        <div className="group relative flex w-full flex-col overflow-hidden rounded-sm border border-gray-100 bg-white shadow-sm transition-all duration-300 ease-in-out">
            <a className="relative mt-3 flex h-60 overflow-hidden rounded-xl" href="#">
                <img
                    className="object-cover w-full h-full p-8 transition-transform duration-300 ease-in-out group-hover:scale-105"
                    src="https://offspring.co.ke/wp-content/uploads/2021/05/huggies-little-swimmers-disposable-swim-pants-421238_2048x2048-700x700.jpg"
                    alt={product.name}
                />
                <span className="absolute top-0 left-0 m-2 bg-black px-2 text-center text-verysmall font-medium text-white">
                    39% OFF
                </span>

                {product.images}
            </a>
            <div className="mt-4 px-5 pb-5">
                <div className="flex mb-2"><a href="#">
                    <p className="text-offspring font-semibold tracking-tight text-slate-900">{product.name}</p>
                </a></div>
                <div className="flex items-center mb-1">
                    {[...Array(5)].map((_, index) => (
                        <Star key={index} className="h-5 w-5 text-yellow-300" fill="currentColor" />
                    ))}
                    <span className="mr-2 ml-3 rounded bg-yellow-200 px-2.5 py-0.5 text-xs font-semibold">
                        5.0
                    </span>
                </div>
                <div className="mt-2 mb-5 flex items-center justify-between">
                    <p>
                        <span className="text-lg font-bold text-teal-600 ">Ksh. {product.price.default}</span>
                    </p>
                </div>

                {/* Add to cart button with transition effect */}
                <a
                    href="#"
                    className="hidden group-hover:flex items-center justify-center rounded-sm bg-offspring px-3 py-2.5 text-center text-sm font-medium text-white transition-all duration-300 ease-in-out opacity-0 group-hover:opacity-100 hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
                >
                    <ShoppingCart className="mr-2 h-6 w-6" />
                    Add to cart
                </a>
            </div>
        </div>
    );
};

export default ProductCard;
