import React from 'react';

export const items = [
    {
        id: 1,
        imgSrc: "https://offspring.co.ke/wp-content/uploads/2024/06/009.png",
        title: "GIFT SHOP",
        description: "Sweet surprises for little ones",
    },
    {
        id: 2,
        imgSrc: "https://offspring.co.ke/wp-content/uploads/2024/05/DjuoKZMW4AAAodk-removebg-preview.png",
        title: "BUY KENYA BUILD KENYA",
        description: "Explore authentic Kenyan-made products crafted with pride",
    },
    {
        id: 3,
        imgSrc: "https://offspring.co.ke/wp-content/uploads/2024/06/123-1.jpg",
        title: "MOM FAVES",
        description: "Check out what Moms in our community love",
    },
    {
        id: 4,
        imgSrc: "https://offspring.co.ke/wp-content/uploads/2024/05/best-seller-logo-template-illustration-suitable-for-branding-marketing-label-mark-product-factory-website-education-etc-free-vector-removebg-preview.png",
        title: "BEST SELLERS",
        description: "Hot picks!! - Discover what’s trending",
    },
    {
        id: 5,
        imgSrc: "https://offspring.co.ke/wp-content/uploads/2024/06/tthh.webp",
        title: "PREMIUM BUYS",
        description: "Indulge in luxury for you and your little one",
    },
];

const Categories: React.FC = () => {
    return (
        <div className="bg-white-100">
            <div className="mx-auto">
                <div className="mx-auto max-w-2xl text-center sm:py-24 lg:max-w-none lg:py-8">
                    <h1 className="text-3xl font-bold mb-8">shop by interest</h1>
                    <div className="flex justify-around items-center py-8 bg-white">
                        {items.map((item) => (
                            <div key={item.id} className="text-center max-w-xs">
                                <div className="w-40 h-40 mx-auto">
                                    <img
                                        src={item.imgSrc}
                                        alt={item.title}
                                        className="rounded-full object-cover w-full h-full"
                                    />
                                </div>
                                <h3 className="text-xl font-semibold mt-4">{item.title}</h3>
                                <p className="text-gray-600">{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Categories;
